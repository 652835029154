import get from 'lodash/get'
const idkit_authorize_url = `${process.env.REACT_APP_IDKIT_URL}/oidc/v1/authorize`;
const response_type = "code id_token token";
const redirect_uri = `${document.location.origin}/vendorData`;

export const getScopes = (brand) => {
  return get(process.env, `REACT_APP_SCOPES_CONFIG_${brand.toUpperCase()}`, "");
};

const getQueryParamsWithoutClientId = (brand) =>
  `&scope=${getScopes(brand)}` +
  `&response_type=${response_type}` +
  `&redirect_uri=${redirect_uri}` +
  "&state=secretState" +
  "&nonce=12345678901234567890123456789012";


const clientIDMap = {
  "Volkswagen": process.env.REACT_APP_VW_IDK_CLIENT_ID,
  "Skoda": process.env.REACT_APP_SKODA_IDK_CLIENT_ID,
  "Seat": process.env.REACT_APP_SEAT_IDK_CLIENT_ID,
  "Cupra": process.env.REACT_APP_CUPRA_IDK_CLIENT_ID
};

export { getQueryParamsWithoutClientId, idkit_authorize_url, clientIDMap };
