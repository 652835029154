const getUserStatus = userStatus => {
  const {
    profileEnrichment,
    vehiclesEnrollments,
    userIdentification
  } = userStatus;

  return {
    profileEnrichment: {
      name: 'Profile Completion',
      status: profileEnrichment.status
    },
    vehiclesEnrollment: {
      name: 'Owner Verification',
      status: vehiclesEnrollments[0].status
    },
    userIdentificationStep: {
      name: 'Identity Verification',
      status: userIdentification.status
    }
  };
};

export { getUserStatus };
