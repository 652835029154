import React, { Component } from "react";
import {
  getQueryParamsWithoutClientId,
  idkit_authorize_url,
  clientIDMap
} from "../utils/authUtils";
import DropDown from "./Dropdown";
import { Redirect } from "react-router-dom";
import { mockVendorUrls } from "../Common/utils";

const environmentList = [
  "Production",
  "Preproduction",
  "Sandbox",
  "Acceptance",
  "Local",
  "Static"
];

const brandList = ["Volkswagen", "Skoda", "Seat", "Cupra"];

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToStatic: false,
      brand: "Volkswagen"
    };
    sessionStorage.setItem('brand', "Volkswagen")

  }

  handleEnvironmentChange = environment => {
    if (environment === "Static") {
      this.setState({ redirectToStatic: true });
      return;
    }

    this.redirectMockVendor(environment);
  };

  handleBrandChange = brand => {
    sessionStorage.setItem('brand', brand)
    this.setState({ brand: brand });
  };

  redirectMockVendor = mockVendorEnvironment =>
    (window.location.href = mockVendorUrls[mockVendorEnvironment]);

  handleSubmit = evt => {
    evt.preventDefault();
    window.location.href = this.getIDKitLoginURL();
  };

  getIDKitLoginURL = () =>
    `${idkit_authorize_url}?${encodeURI(this.constructQueryParams())}`;

  getClientIDForBrand = () => clientIDMap[this.state.brand];

  constructQueryParams = () =>
    `client_id=${this.getClientIDForBrand()}` + getQueryParamsWithoutClientId(this.state.brand);

  render() {
    if (this.state.redirectToStatic) {
      return <Redirect to="/staticPages" />;
    }

    return (
      <>
        <DropDown
          dropDownList={environmentList}
          labelText="Environment"
          required={true}
          value={process.env.REACT_APP_VAS_ENVIRONMENT}
          onChange={e => this.handleEnvironmentChange(e.target.value)}
          description={`When testing in Production please make sure you have valid test data.`}
        />
        <DropDown
          dropDownList={brandList}
          labelText="Brand"
          required={true}
          onChange={e => this.handleBrandChange(e.target.value)}
        />

        <div className="py-5 text-center">
          <h2>Vehicle Activation Service</h2>
          <p className="lead">
            This tool is used to demonstrate and test the flow of Vehicle
            Activation Service.Login to use the application
          </p>
        </div>
        <form className="needs-validation" onSubmit={e => this.handleSubmit(e)}>
          <button className="btn btn-primary btn-lg btn-block" type="submit">
            Submit
          </button>
        </form>
      </>
    );
  }
}

export default LoginPage;
