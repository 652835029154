import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { getUserStatus } from './UserStatusParser';
import httpClient from '../httpClient/httpClient';

const SuccessCallBack = () => {
  const [result, setResult] = useState('');
  const vin = sessionStorage.getItem('vin');
  const access_token = sessionStorage.getItem('access_token');
  const userId = jwt_decode(access_token).sub;
  const url = `${document.location.origin}/auth/users/${userId}/status?vin=${vin}`;
  useEffect(() => {
    httpClient({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
        Accept: 'application/json'
      }
    }).then(response => setResult(getUserStatus(response.data)));
  }, []);

  return (
    <>
      <div className="py-5 text-center">
        <h2>Vehicle Activation Service</h2>
        <p className="lead">The results from Vehicle Activation Service.</p>
      </div>
      <div className="row">
        <div className="col-sm-12 order-md-1">
          <h4 className="mb-3">Results</h4>
          {result ? (
            <>
              <ul style={{ listStyleType: 'none' }}>
                {Object.keys(result).map(key => {
                  switch (result[key].status) {
                    case 'completed':
                      return (
                        <li key={key}>
                          <span className="badge badge-success">OK</span>{' '}
                          {result[key].name}
                        </li>
                      );
                    case 'in_progress':
                      return (
                        <li key={key}>
                          <span className="badge badge-warning">Pending</span>{' '}
                          {result[key].name}
                        </li>
                      );
                    case 'not_started':
                      return (
                        <li key={key}>
                          <span className="badge badge-secondary">
                            Not started
                          </span>{' '}
                          {result[key].name}
                        </li>
                      );
                    case 'not_possible':
                      return (
                        <li key={key}>
                          <span className="badge badge-danger">Failed</span>{' '}
                          {result[key].name}
                        </li>
                      );
                    default:
                      return null;
                  }
                })}
              </ul>
              <h4 className="mb-3"> JSON response</h4>
              <pre>{JSON.stringify(result, null, '\t')}</pre>
            </>
          ) : (
            'Loading.....'
          )}
        </div>
      </div>
    </>
  );
};

export default SuccessCallBack;
