import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Form from './Components/Form';
import LoginPage from './Components/LoginPage';
import SuccessCallBack from './Components/SuccessCallBack';
import StaticPages from './Components/StaticPages/StaticPages';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/vendorData" component={Form} />
        <Route exact path="/staticPages" component={StaticPages} />
        <Route path="/:vendor/success" component={SuccessCallBack} />
        <Route path="/:vendor/cancel" component={SuccessCallBack} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
