export const VOLKSWAGEN = 'Volkswagen';
export const SKODA = 'Skoda';
export const SEAT = 'Seat';
export const CUPRA = 'Cupra';
export const GuestUserFlow = 'GU_1';
export const STORAGE_KEYS = {
  ACCESS_TOKEN_KEY: 'access_token',
  VIN_KEY: 'vin',
  BRAND: 'brand'
};
export const DefaultsuccessUrl = 'https://www.myvolkswagen.net';
export const GU_VW_successUrl =
  'https://www.volkswagen.de/de/besitzer-und-nutzer/myvolkswagen.html';
export const GU_Skoda_successUrl =
  'https://www.skoda-auto.com/connectivity/mobile-apps#myskoda';
export const DefaultVinValue = '';
