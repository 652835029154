import React, { Fragment } from 'react';
const TextInputField = ({
  id,
  labelText,
  name,
  placeholderText,
  description,
  onChange,
  ...otherProps
}) => {
  return (
    <Fragment>
      <div className="col-sm-12 mb-3">
        <label htmlFor={name}>{labelText}</label>
        <input
          type="text"
          className="form-control"
          id={id}
          placeholder={placeholderText}
          name={name}
          onChange={onChange}
          {...otherProps}
        />
        <small className="form-text text-muted">{description}</small>
      </div>
    </Fragment>
  );
};

export default TextInputField;
