import React from 'react';
import pagesList from './pagesList';
import './StaticPages.css';

const StaticPages = () => {
  const staticDomain =
    'https://vehicle-activation-service-staticpages.apps.mega.cariad.cloud';

  return (
    <div className="box_staticPages">
      <h2>Static Pages list</h2>
      <ul>
        {pagesList.map(({ route, displayText }) => (
          <li key={route}>
            <a
              href={`${staticDomain}${route}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {displayText}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StaticPages;
