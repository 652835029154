import React, { useState } from 'react';
import TextInputField from './InputField';
import DropDown from './Dropdown';
import queryString from 'query-string';
import httpClient from '../httpClient/httpClient';

import jwt_decode from 'jwt-decode';
import {
  GuestUserFlow,
  STORAGE_KEYS,
  DefaultsuccessUrl,
  VOLKSWAGEN,
  SKODA,
  GU_VW_successUrl,
  GU_Skoda_successUrl,
  DefaultVinValue
} from '../constants';

const { ACCESS_TOKEN_KEY, VIN_KEY, BRAND } = STORAGE_KEYS;
const brand = sessionStorage.getItem(BRAND);

const Form = () => {
  const flowCombinations = [
    'VW_PE_VE_UI_1',
    'VW_PE_VE_1',
    'VW_PE_1',
    'VW_Dynamic_1',
    'VW_UI',
    GuestUserFlow
  ];
  const [requestedSteps, setCombination] = useState('VW_PE_VE_UI_1');
  const flowCombinationsAppMood = ['', 'dark', 'light'];
  const [appMood, setAppMood] = useState('');
  const [vin, setVin] = useState(DefaultVinValue);
  const [successUrl, setSuccessUrl] = useState(() =>
    requestedSteps === GuestUserFlow ? '' : DefaultsuccessUrl
  );
  const [cancelUrl, setCancelUrl] = useState('');
  const [externalAppUrl, setExternalAppUrl] = useState('');

  const processQueryParams = () => {
    sessionStorage.setItem(VIN_KEY, vin);
    const urlQueryStringMap = queryString.parse(window.location.hash);
    let urlAccessToken = urlQueryStringMap[ACCESS_TOKEN_KEY];

    if (urlAccessToken) {
      sessionStorage.setItem(ACCESS_TOKEN_KEY, urlAccessToken);
    }
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    processQueryParams();

    const userId = jwt_decode(sessionStorage.getItem(ACCESS_TOKEN_KEY)).sub;
    const sessionBody = {
      success: `${successUrl}`,
      vin: `${vin}`,
      requestedSteps: `${requestedSteps}`,
      cancelUrl: `${cancelUrl}`,
      externalAppUrl: `${externalAppUrl}`,
      appMood: `${appMood}`
    };
    httpClient({
      method: 'POST',
      url: `${process.env.REACT_APP_VAS_AUTH_API_URL}/users/${userId}/session`,
      headers: { Accept: 'application/json' },
      data: sessionBody
    }).then(response => {
      // window.location.href = response.headers.location;
      window.location.href = `${process.env.REACT_APP_VAS_UI_URL}/?transactionId=${response.data.transactionId}`;
    });
  };

  const setStateForGu = () => {
    switch (sessionStorage.getItem(BRAND)) {
      case VOLKSWAGEN:
        setSuccessUrl(GU_VW_successUrl);
        setVin('VOLKSWAGEN_MOD3_DUMMY_VIN');
        break;
      case SKODA:
        setSuccessUrl(GU_Skoda_successUrl);
        break;
      default:
        setSuccessUrl('');
        setVin('');
    }
  };

  const setStateForDefault = () => {
    setSuccessUrl(DefaultsuccessUrl);
    setVin(DefaultVinValue);
  };

  function onChangeRequestedSteps({ target: { value } }) {
    value === GuestUserFlow ? setStateForGu() : setStateForDefault();
    setCombination(value);
  }

  function onChangeAppMood({ target: { value } }) {
    setAppMood(value);
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>Vehicle Activation Service</h2>
        <p className="lead">
          This tool is used to demonstrate and test the flow of Vehicle
          Activation Service.
        </p>
      </div>
      <div className="row">
        <div className="col-sm-12 order-md-1">
          <h4 className="mb-3"></h4>
          <form className="needs-validation" onSubmit={e => handleSubmit(e)}>
            <hr className="mb-4" />
            <DropDown
              dropDownList={flowCombinations}
              value={requestedSteps}
              required={true}
              onChange={e => onChangeRequestedSteps(e)}
              labelText="Flow combination"
            ></DropDown>
            <TextInputField
              labelText="Vehicle Identification Number (optional)"
              id={'vin'}
              name={'vin'}
              value={vin}
              onChange={e => setVin(e.target.value)}
              placeholderText={'VINNYVINVIN123456'}
            />
            <TextInputField
              labelText="Success URL"
              id={'success-url'}
              name={'success-url'}
              value={successUrl}
              onChange={e => setSuccessUrl(e.target.value)}
              placeholderText={'vendor/success'}
              required={requestedSteps !== GuestUserFlow}
              description={
                'The URL we send the user to when they finish the process successfully.'
              }
            />
            <TextInputField
              labelText="Cancel URL (optional)"
              id={'cancel-url'}
              name={'cancel-url'}
              value={cancelUrl}
              onChange={e => setCancelUrl(e.target.value)}
              placeholderText={'vendor/cancel'}
              description={
                'The URL we send the user to when they cancel the process.'
              }
            />
            <TextInputField
              labelText="External App URL (optional)"
              id={'external-app-url'}
              name={'external-app-url'}
              value={externalAppUrl}
              onChange={e => setExternalAppUrl(e.target.value)}
              placeholderText={'vendor/externalAppUrl'}
              description={
                'The URL to which is been redirected when request is for QRCode process.'
              }
            />
            <DropDown
              dropDownList={flowCombinationsAppMood}
              value={appMood}
              required={false}
              onChange={e => onChangeAppMood(e)}
              labelText="App Mood"
            ></DropDown>
            <hr className="mb-4" />
            <button className="btn btn-primary btn-lg btn-block" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Form;
