
const mockVendorUrls = {
  Production: 'https://vas-mock-vendor-prod.apps.mega.cariad.cloud/',
  Preproduction: 'https://vas-mock-vendor-preprod.apps.mega.cariad.cloud/',
  Sandbox: 'https://vas-mock-vendor-sandbox.apps.mega.cariad.cloud/',
  Acceptance: 'https://vas-mock-vendor-dev.apps.mega.cariad.cloud/',
  Local: 'http://localhost:3000'
};

export { mockVendorUrls };
