import React, { Fragment } from 'react';

const DropDown = ({
  labelText,
  value,
  onChange,
  dropDownList,
  htmlText,
  description,
  ...otherProps
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-center">
        <div className="col-sm-12 mb-3 ">
          <label htmlFor={labelText}>{labelText}</label>
          <select
            className="custom-select d-block w-100"
            id="flowcombo"
            value={value}
            onChange={onChange}
            name={labelText}
            {...otherProps}
          >
            {dropDownList.map(item => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <small className="form-text text-muted">{description}</small>
        </div>
      </div>
    </Fragment>
  );
};

export default DropDown;
