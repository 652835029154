import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {mockVendorUrls} from "../Common/utils";

const accessTokenKey = 'access_token';
const expiryMarginInSeconds = 60;

function httpClient(config) {
  let accessToken = sessionStorage.getItem(accessTokenKey);

  let isInvalid = false;
  let expiryTimestamp;
  try {
    expiryTimestamp = jwt_decode(accessToken).exp;
  } catch (InvalidTokenError) {
    isInvalid = true;
  }

  const nowInSeconds = Date.now() / 1000;

  if (isInvalid || nowInSeconds > expiryTimestamp - expiryMarginInSeconds) {
    window.location = mockVendorUrls["Acceptance"];
    return new Promise((resolve, reject) => {});
  } else {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + accessToken
    };
    return axios(config);
  }
}

export default httpClient;
